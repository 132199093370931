<template>
<div class="container">
  <div class="leftMenu">
    <div class="moduleTitle">印刷厂工作台</div>
<!--    <ul class="menuList">-->
<!--      <li class="menuItem"-->
<!--          v-for="(order,index) of routerList"-->
<!--          :class="{active: $route.name === order.link}"-->
<!--          :key="index"-->
<!--          @click="$router.push(order.link)"-->
<!--      >{{ order.label }}</li>-->
<!--    </ul>-->
  </div>
  <div class="content">
    <router-view></router-view>
  </div>
</div>
</template>

<script>
export default {
  name: 'plantWorkbench',
  data () {
    return {
      routerList: [
        {
          label: '待确认订单',
          link: 'orderConfirm'
        },
        {
          label: '我的订单',
          link: 'myOrder'
        }
      ]
    }
  },
  created () {
    if (this.$route.name === 'plantWorkbench') {
      this.$router.push('plantWorkbench/orderConfirm')
    }
  }
}
</script>

<style scoped lang="scss">
.container{
  height: calc(100% - 110px);
  margin: 0 20px;
  display: flex;
  .leftMenu{
    width: 200px;
    border-width: 0 1px;
    border-color: #ccc;
    border-style: none solid;
    .moduleTitle{
      line-height: 80px;
      font-weight: bold;
      padding-left: 20px;
      font-size: 20px;
    }
    .menuList{
      .menuItem{
        color: #7c818b;
        line-height: 50px;
        box-sizing: border-box;
        padding-left: 50px;
        background-size: auto;
        transition: 0.5s;
        &.active{
          position: relative;
          background-color: #e2ebfd;
          &:before{
            content: '';
            width: 4px;
            height: 30px;
            background-color: #7a96ea;
            position: absolute;
            left: 0;
            top: 10px;
          }
        }
        &:hover{
          color: #1A1A1A;
          background-color: #edf2fc;
          cursor: pointer;
        }
      }
    }
  }
  .content{
    flex: 1;
  }
}
</style>
